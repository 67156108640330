"use strict";
/// <reference types="bootstrap"/>
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.CollapseMenu = void 0;
var collapse_1 = require("./../collapse");
var backdrop_1 = require("./../backdrop");
var scrollbar_helper_1 = require("./../scrollbar-helper");
var scrollspy_1 = require("./../scrollspy");
var CollapseMenu = /** @class */ (function (_super) {
    __extends(CollapseMenu, _super);
    function CollapseMenu() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    CollapseMenu.prototype.run = function () {
        _super.prototype.run.call(this);
        this.initBackdrop();
        this.initScrollBarHelper();
        this.ScrollSpy();
        this.initListeners();
    };
    CollapseMenu.prototype.initBackdrop = function () {
        this.backdrop = new backdrop_1.Backdrop({});
    };
    CollapseMenu.prototype.initScrollBarHelper = function () {
        this.scrollBarHelper = new scrollbar_helper_1.ScrollBarHelper({});
    };
    CollapseMenu.prototype.ScrollSpy = function () {
        this.scrollSpy = new scrollspy_1.ScrollSpy({ element: document.body });
    };
    CollapseMenu.prototype.initListeners = function () {
        var _this = this;
        this.config.element.addEventListener('hidden.bs.collapse', function (event) {
            _this.scrollBarHelper.reset();
            _this.backdrop.hide();
        });
        this.config.element.addEventListener('shown.bs.collapse', function (event) {
            _this.scrollBarHelper.hide();
            _this.backdrop.show();
        });
    };
    return CollapseMenu;
}(collapse_1.Collapse));
exports.CollapseMenu = CollapseMenu;
